import { graphql } from "gatsby"
import React,{useState,useEffect} from "react"
import CampaignBanner from "../components/CampaignBanner/CampaignBanner"
import CampaignColBlock from "../components/CampaignColBlock/CampaignColBlock"
import CampaignIconModule from "../components/CampaignIconModule/CampaignIconModule"
import CampaignPayment from "../components/CampaignPayment/CampaignPayment"
import CampaignProperties from "../components/CampaignProperties/CampaignProperties"
import CampignWithIcon from "../components/CampignWithIcon/CampignWithIcon"
import ContactDetails from "../components/ContactDetails/ContactDetails"
import GallerySlider from "../components/GallerySlider/GallerySlider"
import SecondaryHeader from "../components/SecondaryHeader/SecondaryHeader"
import SectionViewerComponent from "../components/SectionViewerComponent/SectionViewerComponent"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { removeDataAttributes } from "../components/Common/utils"
import logoBlack from "../images/logo-white.svg"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"


const CampaignTemplate = ({ data, pageContext }) => {
  const [pageData, setPageData] = useState(data?.strapiCampign)
  const windowSearch= typeof window!=="undefined"?window.location.search:""
  const [loading, setLoading] = useState(() => {
    const checkurlParams = new URLSearchParams(windowSearch);
    const checkisPreview = checkurlParams.get('debug') === 'preview';
    return checkisPreview;
  });
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string


  const campaignData = pageData


  const campaignProperty = data?.allNewdevelopments?.edges

  const whatsappData =
    data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]

  let crmIds = campaignData?.property_id?.split(",")

  const locationData = [campaignData?.location_module]

  const nodeList =
    typeof document !== "undefined" ? document.querySelectorAll("section") : ""
  const array = [...nodeList]
  const filteredArray = array.filter(element => element.hasAttribute("id"))
  const idArray = filteredArray.map(element => element.getAttribute("id"))

  const { isLargeScreen } = useDeviceMedia()

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/campigns/'+urlParams.get('strapi_id')+'?populate[0]=banner_image&populate[1]=thankyou_image&populate[2]=amenities_data.icon_module.image&populate[3]=image_text_block.left_right_block.image&populate[4]=image_text_block.left_right_block.menu&populate[5]=location_module.image&populate[6]=location_module.menu&populate[7]=gallery_image_list.image&populate[8]=development_data.icon_module.image&populate[9]=payment.payment_items&populate[10]=payment.payment_block_image&populate[11]=single_image_block.image&populate[12]=single_image_block.menu&populate[13]=contact_info&populate[14]=contact_info&populate[15]=logo&populate[16]=development_data.icon_module&populate[17]=image_text_block.left_right_block.menu.parent&populate[18]=location_module.menu.parent&populate[19]=seo'

    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
      setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)
        let myPrevData;
        if(pageData){
          myPrevData = pageData
        } else {
          myPrevData = {};
          myPrevData.banner_image = {}
          myPrevData.logo = {}
          myPrevData.thankyou_image = {}
          myPrevData.amenities_data = {}
          myPrevData.amenities_data.icon_module = []
          myPrevData.image_text_block = {}
          myPrevData.image_text_block.left_right_block=[]
          myPrevData.location_module = {}
          myPrevData.gallery_image_list = []
          myPrevData.development_data = {}
          myPrevData.development_data.icon_module = []
          myPrevData.payment={}
          myPrevData.payment.payment_items=[]
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          myPrevData.banner_image = previewData?.banner_image
          myPrevData.logo = previewData?.logo
          myPrevData.banner_content = previewData?.banner_content
          myPrevData.imagetransforms = previewData?.imagetransforms
          myPrevData.image_text_block = previewData?.image_text_block
          myPrevData.slug = previewData?.slug
          myPrevData.gallery_image_list = previewData?.gallery_image_list
          myPrevData.development_data = previewData?.development_data
          myPrevData.developer = previewData?.developer
          myPrevData.contact_info = previewData?.contact_info
          myPrevData.amenities_data = previewData?.amenities_data
          myPrevData.address = previewData?.address
          myPrevData.location_module = previewData?.location_module
          myPrevData.payment = previewData?.payment
          myPrevData.single_image_block = previewData?.single_image_block
          myPrevData.thankyou_content = previewData?.thankyou_content
          myPrevData.title = previewData?.title
          myPrevData.units_text = previewData?.units_text
          myPrevData.whatsapp_text = previewData?.whatsapp_text
          myPrevData.whatsapp_no = previewData?.whatsapp_no
          myPrevData.module_title = previewData?.module_title
          myPrevData.module_content = previewData?.module_content
          myPrevData.form_btn_text = previewData?.form_btn_text
          setPageData(pageData => ({ ...pageData, myPrevData }));
          sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
      sessionStorage.removeItem("previewMeta")
    }

  },[])

    if(loading){
      return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img
                className="logo-white loader-logo"
                src={logoBlack}
                alt="logo"
              />
            </div>
          </div>
        </section>
      )
    }
    if(enablePreview){
      return null
    }

  return (
    <Layout isCampaign>
      
      <SecondaryHeader
        idArray={idArray}
        strapiId={campaignData?.strapi_id}
        isCampaign
        whatsapp_num={campaignData?.whatsapp_no}
        whatsapp_text={campaignData?.whatsapp_text}
      />
     
      <CampaignBanner isPreviewEnabled={isPreviewEnabled} campaignData={campaignData} />
      {isLargeScreen && (
        <SectionViewerComponent
          idArray={idArray}
          strapiId={campaignData?.strapi_id}
          isCampaign
          whatsapp_num={campaignData?.whatsapp_no}
        />
      )}
      {campaignData?.amenities_data?.icon_module && (
        <CampignWithIcon
          id={campaignData?.strapi_id}
          icon_module={campaignData?.amenities_data?.icon_module}
          {...campaignData?.amenities_data}
          imagetransforms={campaignData?.imagetransforms}
          campaign={true}
        />
      )}
      {campaignData?.image_text_block?.left_right_block?.length > 0 && (
        <CampaignColBlock
          campaignData={campaignData}
          id={campaignData?.strapi_id}
          imagetransforms={campaignData?.imagetransforms}
          {...campaignData?.image_text_block}
          campaign={true}
          singleblock={true}
        />
      )}
      {locationData?.length > 0 && locationData[0] && (
        <CampaignColBlock
          id={campaignData?.strapi_id}
          imagetransforms={campaignData?.imagetransforms}
          left_right_block={locationData}
          campaign={true}
          singleblock={true}
          locationblock={true}
          campaignData={campaignData}
        />
      )}
      {campaignData?.gallery_image_list?.length > 0 && (
        <GallerySlider galleryData={campaignData} />
      )}
      {campaignData?.development_data && (
        <CampaignIconModule
          {...campaignData?.development_data}
          id={campaignData?.strapi_id}
          imagetransforms={campaignData?.imagetransforms}
          icon_module={campaignData?.development_data?.icon_module}
          campaignData={campaignData}
        />
      )}
      {campaignData?.payment && (
        <CampaignPayment
          paymentData={campaignData?.payment}
          id={campaignData?.strapi_id}
          imagetransforms={campaignData?.imagetransforms}
          ggfx_results={campaignData?.ggfx_results}
        />
      )}
      {campaignData?.single_image_block?.length > 0 && (
        <CampaignColBlock
          id={campaignData?.strapi_id}
          left_right_block={campaignData?.single_image_block}
          campaign={true}
          singleblock={true}
          campaignData={campaignData}
          imagetransforms={campaignData?.imagetransforms}
        />
      )}
      {campaignProperty?.length > 0 && !isPreviewEnabled && (
        <CampaignProperties propertyData={campaignProperty}  campaignData={campaignData}/>
      )}
      <ContactDetails
        whatsappData={whatsappData}
        data={campaignData}
        type="campaign"
        whatsapp_text={campaignData?.whatsapp_text}
      />
    </Layout>
  )
}


export const Head = ({ data }) => {
  const campData = data?.strapiCampign
  let myimgtransforms = ""
  if (campData?.imagetransforms?.banner_image_Transforms) {
    myimgtransforms = JSON.parse(
      campData?.imagetransforms?.banner_image_Transforms
    )
  }

  let myimgtransforms1 = ""
  if (campData?.imagetransforms?.metaog_Transforms) {
    myimgtransforms = JSON.parse(
      campData?.imagetransforms?.metaog_Transforms
    )
  }


  let getImg= campData?.banner_image?.url? campData?.banner_image?.url:""
  let findImage = getImg?.substring(getImg?.indexOf("i"));

  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""

  

  let imageUrl=""
  if(myimgtransforms1){
    const ProcessedResult=findImage&&myimgtransforms1[findImage]?myimgtransforms1[findImage][ext]:""
    imageUrl=ProcessedResult?ProcessedResult["600x400"]:getImg
  }
  else if(myimgtransforms){
    // ogRender()
    const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
    imageUrl=ProcessedResult?ProcessedResult["768x397"]:campData?.banner_image?.url
  }


  //new ggfx image
  const getImage =
  campData?.banner_image?.url
      ? campData?.banner_image?.url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : ""
  const filterImage =
    getImage && campData?.ggfx_results?.length > 0
      ? campData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""

  // filter image with image size
  const getOGImg =
    filterImage?.length > 0 && filterImage[0].transforms?.length > 0
      ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
      : ""

   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600x400",
      id: campData.strapi_id,
      field: "metaog",
      contentType: "campign"
    })
  }

  useEffect(()=>{
    if(!getOGImg?.length>0)
        ogRenderNew()
  },[]) 

  //----
  const pageImage=getOGImg?.length>0?getOGImg[0].url:imageUrl

  const metaTitle = campData?.seo?.metaTitle ? campData.seo?.metaTitle :`${campData?.title}`
  const metaDesc = campData?.strapi_id===8?
  "Get market insights, key trends, and projections from property experts."
  :`Know more about ${campData?.title} here.`

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
          if(parsedData?.metaTitle){
            setPreMetaTitle(parsedData?.metaTitle)
          }
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
      }
    }
  }, 4000);

  // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
      document.title =  `${preMetaTitle} | haus & haus`;
    }
  }, [preMetaTitle]);

  return <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle} description={metaDesc} imageUrl={pageImage}></SEO>
}

export default CampaignTemplate

export const query = graphql`
  query ($page_id: Int, $property_id: [String!]!) {
    strapiCampign(strapi_id: { eq: $page_id }) {
      ...CampaignFragment
    }
    allNewdevelopments(filter: { crm_id: { in: $property_id } }) {
      edges {
        node {
          images {
              url
          }

          slug
          id
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          project_title
          crm_id
          development_type
          developer
          strapi_id
          area
          search_type
        }
      }
    }
    allStrapiSiteConfig {
      edges {
        node {
          whatsapp_message
        }
      }
    }
  }
`
