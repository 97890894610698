import parse from "html-react-parser"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container, Modal } from "react-bootstrap"
import FormFields from "../../../static/forms/campaign.json"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import DefaultForm from "../forms/default-form-layout"
import "./CampaignBanner.scss"
import GGFXImage from "../../modules/GGFXImage"
import useDeviceWidth from "../../hooks/useDeviceWidth"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const CampaignBanner = ({ campaignData,isPreviewEnabled }) => {
  const [show, setShow] = useState(false)
  const bannerData = campaignData
  const {
    banner_image,
    banner_content,
    developer,
    logo,
    title,
    strapi_id,
    form_data,
    units_text,
    address,
    thankyou_image,
    thankyou_content
  } = bannerData

  const formContent = form_data?.length > 0 ? form_data[0] : ""

  const userText = formContent?.user_content?.data?.user_content

  const { isLargeScreen, isMobile } = useDeviceMedia()

  var imagename = "campign.banner_image.campaign_banner_image"
  var mobileImageName = "campign.mobile_image.mobile_campign"
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const formTitle = bannerData?.contact_info?.title

  const handleModal = data => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  const btnTextCamp=campaignData?.form_btn_text ? campaignData?.form_btn_text:""
  const deviceWidth = useDeviceWidth()

  return (
    <section className="campaign-banner">
        {deviceWidth<768&&campaignData?.mobile_image?.url ?
            <GGFXImage
              ImageSrc={campaignData?.mobile_image}
              altText={`${campaignData?.mobile_image?.alternativeText ? campaignData?.mobile_image?.alternativeText 
              :title} banner`} 
              imagetransforms={bannerData?.ggfx_results}
              renderer="bgImg"
              imagename={mobileImageName}
              strapiID={strapi_id}
              className="banner-img d-block d-md-none"
          />
            :
          <GGFXImage
            ImageSrc={banner_image}
            altText={title}
            imagetransforms={bannerData?.ggfx_results}
            renderer="bgImg"
            imagename={imagename}
            strapiID={strapi_id}
            className="banner-img"
          />
        }
      <div className="overlay-bg"></div>
      <ScrollAnimation
        animateIn="animate__slideInUp"
        animateOnce
        delay={100}
        offset={100}
      >
        <Container>
          <div className="capaign-banner-sections">
            <div className="campaign-banner-content">
              <div className="content-section">
                {logo?.url && (
                  <div className="logo-campaign">
                    <img
                      src={logo?.url}
                      alt={
                        logo?.altText ? logo.alternativeText : "logo campaign"
                      }
                    />
                  </div>
                )}
                <h1 className="title">{title}</h1>
                {developer && (
                  <p className="developer">
                    <span>by</span> {developer}
                  </p>
                )}
                {(banner_content?.data?.banner_content || (isPreviewEnabled&&banner_content)) &&
                <p className="banner-description">
                  <ContentModule
                    Content={banner_content?.data?.banner_content||banner_content}
                  />
                </p>
                }
                <button
                  onClick={handleModal}
                  className="button button-filled-green"
                >
                  {btnTextCamp?btnTextCamp:
                  "register your interest"
                  }
                </button>
                {isLargeScreen && campaignData?.strapi_id!==8 && <p className="offer-text">{units_text}</p>}
              </div>
            </div>
            {!isLargeScreen && (
              <div className="campaign-banner-form">
                <div className="campaign-form-wrapper">
                  <DefaultForm
                    fields={FormFields}
                    formtagclassname="campaign-form-desktop"
                    formTitle={formTitle}
                    userContent={userText}
                    to_email_id={formContent?.admin_mail}
                    emailSubUser={formContent?.email_subject_user}
                    emailSubAdmin={formContent?.email_subject_admin}
                    form_type="campaign-banner"
                    selectType="campaign"
                    units_text={units_text}
                    thankingAddress={address}
                    sourceUrl={pageurl}
                    // showOnlyme={true}
                    pageTitle={campaignData?.title}
                    form_id_value="campign-banner-id"
                    phone_id_value="desktop-id-campaign"
                    thankyou_image={thankyou_image}
                    thankyou_content={thankyou_content}
                    btnTextCamp={btnTextCamp}
                    customFormName={`Campaign - ${title}`}
                  />
                </div>
              </div>
            )}
          </div>
        </Container>
      </ScrollAnimation>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form-campaign"
      >
        <Modal.Body>
          <div className="popup-form-wrapper-campaign">
            {/* <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div> */}
            <div>
              <DefaultForm
                fields={FormFields}
                formTitle={formTitle}
                userContent={userText}
                to_email_id={formContent?.admin_mail}
                emailSubUser={formContent?.email_subject_user}
                emailSubAdmin={formContent?.email_subject_admin}
                selectType="campaign"
                sourceUrl={pageurl}
                pageTitle={campaignData?.title}
                thankingAddress={address}
                // showOnlyme={true}
                form_id_value="campign-banner-id-mobile"
                phone_id_value="mobile-id-campaign"
                thankyou_image={thankyou_image}
                thankyou_content={thankyou_content}
                btnTextCamp={btnTextCamp}
                handleClose={handleClose}
                customFormName={`Campaign - ${title}`}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default CampaignBanner
