import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import CampaignPropertyCard from "../CampaignPropertyCard/CampaignPropertyCard"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./CampaignProperties.scss"
import useDeviceMedia from "../../hooks/useDeviceMedia"
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const CampaignProperties = props => {
  const propertyData = props.propertyData
  const campaignData=props.campaignData

  const {isMobile} = useDeviceMedia()

  return (
    <div className="campaign-properties-wrapper">
      <Container>
        <div className="properties-module">
          <ScrollAnimation
            animateIn="animate__slideInUp"
            animateOnce
            delay={100}
            offset={100}
          >
            <div className="content-section">
              <h2>{campaignData?.module_title}</h2>
              <p className="description">
              <ContentModule Content={campaignData?.module_content?.data?.module_content} />
              </p>
            </div>
          </ScrollAnimation>
          {propertyData?.length > 0 && (
            <div className="slider-section">
              <CustomSlider
                enableArrows
                className="campaign_propertis-slider"
                slidecount={isMobile?1:3}
              >
                {propertyData?.map((property, index) => {
                  const image =
                    property?.node?.images?.length > 0
                      ? property?.node?.images[0]
                      : ""
                  return (
                    <CampaignPropertyCard
                      developer={property?.node?.developer}
                      development_type={property?.node?.development_type}
                      crm_id={property?.node?.crm_id}
                      project_title={property?.node?.project_title}
                      images={image}
                      // imagetransforms={property?.node?.imagetransforms}
                      search_type={property?.node?.search_type}
                      strapi_id={property?.node?.strapi_id}
                      slug={property?.node?.slug}
                      area={property?.node?.area}
                      ggfx_results={property?.ggfx_results}
                    />
                  )
                })}
              </CustomSlider>
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

export default CampaignProperties
